import { useMutation, useQuery } from "react-query";
import { fetchGETSYS, fetchPOSTWithoutEmail, fetchPOSTWithOutEmailBlob } from "utils/https/https";


type RequestBody = {
    username?: string
    serviceClient?: string
}

export const UseGetSysKams = (data: any) => {
    const username = data && data.username ? data.username : null


    return useQuery(['UseGetSysKams', username], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (username) {
                serchParms.append('username', username)
            }

            const response = await fetchGETSYS(`commissions/User/ListUsers?&${serchParms.toString()}`)
            return response

        } catch (error) {
            throw error
        }

    })
}

export const UseGetSysClients = (data: any) => {

    const name = data && data.name ? data.name : null


    return useQuery(['UseGetSysClients', name], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (name) {
                serchParms.append('name', name)
            }

            const response = await fetchGETSYS(`commissions/Client/ListClients?&${serchParms.toString()}`)
            return response

        } catch (error) {
            throw error
        }

    })
}






const postDataAuthorizationCompleted = async (data: any) => {


    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
    const authorizationState = data.authorizationState && data.authorizationState.length > 0 ? data.authorizationState : null
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    try {



        const serchParms = new URLSearchParams();

        if (type) {
            serchParms.append('type', type.join(','))
        }

        if (authorizationState) {
            serchParms.append('authorizationState', authorizationState.join(','))
        }

        if (commissionType) {
            serchParms.append('commissionType', commissionType.join(','))
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (authorizationReason) {
            serchParms.append('authorizationReason', authorizationReason)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth.join(','))
        }



        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        // PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }



        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }

        const response = await fetchPOSTWithoutEmail(`commissions/Authorizator/ListCommissions?&CommissionState=finished&${serchParms.toString()}`, requestBody)
        return response



    } catch (error) {
        throw error
    }
}



export const UseMutationDataAuthCompleted = (cb: any) => {
    return useMutation(postDataAuthorizationCompleted, {
        onSuccess: (data) => {
            cb(data)
        }
    })

}



const postDataAuthorizationPendingSys = async (data: any) => {


    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType : null
    const type = data?.type && data?.type.length > 0 ? data?.type : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null


    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null





    try {



        const serchParms = new URLSearchParams();


        if (commissionType) {
            serchParms.append('commissionType', commissionType.join(','))
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type.join(','))
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth.join(','))
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        // PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }


        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }

        const response = await fetchPOSTWithoutEmail(`commissions/Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
        return response



    } catch (error) {
        throw error
    }
}



export const UseMutationDataAuthPendingSys = (cb: any) => {
    return useMutation(postDataAuthorizationPendingSys, {
        onSuccess: (data) => {
            cb(data)
        }
    })

}



const downloadFileAuthorizatorPending = async (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    try {

        const serchParms = new URLSearchParams();

        if (username) {
            serchParms.append('username', username)
        }

        if (serviceClient) {
            serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth)
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        //! PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }

        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }


        const response = await fetchPOSTWithOutEmailBlob(`commissions/Authorizator/DownloadCommissions?authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'authorizatorPendingSysDocument.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);


        return response


    } catch (error) {
        throw error
    }
}


export const UseDownloadFileAuthorizatorPendingSys = (callbackSuccess: any) => {
    return useMutation(downloadFileAuthorizatorPending, {
        onSuccess(data) {
            callbackSuccess(data)
        },
    })
}


const downloadFileAuthorizatorCompleted = async (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
    const authorizationState = data.authorizationState && data.authorizationState.length > 0 ? data.authorizationState[0] : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    try {

        const serchParms = new URLSearchParams();

        if (username) {
            serchParms.append('username', username)
        }

        if (authorizationState) {
            serchParms.append('authorizationState', authorizationState)
        }

        if (serviceClient) {
            serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth)
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        //! PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }

        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }


        const response = await fetchPOSTWithOutEmailBlob(`commissions/Authorizator/DownloadCommissions?&CommissionState=finished&${serchParms.toString()}`, requestBody)
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'authorizatorCompletedSysDocument.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);


        return response


    } catch (error) {
        throw error
    }
}


export const UseDownloadFileAuthorizatorCompletedSys = (callbackSuccess: any) => {
    return useMutation(downloadFileAuthorizatorCompleted, {
        onSuccess(data) {
            callbackSuccess(data)
        },
    })
}