/* eslint-disable react/jsx-no-target-blank */
import { FC, useCallback, useContext, useState } from 'react'

import {
	PATH_COMMISSIONS,
	PATH_COMPLETED_COMMISSIONS_AUTHORIZATION_PAGE,
	PATH_COMPLETED_COMMISSIONS_AUTHORIZATION_SYS_PAGE,
	PATH_COMPLETED_EXCEPTIONS_AUTHORIZATION_PAGE,
	PATH_EXCEPTIONS,
	PATH_PENDING_COMMISSIONS_AUTHORIZATION_PAGE,
	PATH_PENDING_COMMISSIONS_AUTHORIZATION_SYS_PAGE,
	PATH_PENDING_EXCEPTIONS_AUTHORIZATION_PAGE,
	routerNameMap
} from '../../routing/protected/PrivateRoutes'

import { faStamp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CounterContext } from 'context/CounterContext'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser, useTranslator } from 'ufinet-web-functions'

enum AsideMenu {
	NONE,
	CONFIGURATION,
	COMMISSION_PENDING,
	COMMISSION_SYS_PENDING,
	COMMISSION_COMPLETED,
	COMMISSION_SYS_COMPLETED,
	EXCEPTION_PENDING,
	EXCEPTION_COMPLETED
}

enum PrintValues {
	PENDING = 'pending',
}

interface IMenuItemPrint extends IMenuItem {
	id: PrintValues
}

type TemplateRoutes = {
	[key in PrintValues]: string;
};

const AsideMenuMain: FC = () => {

	const {
		commission,
		pendingCommissions,
		pendingExceptions,
		pendingSys,
		commissionSys,
	} = useContext(CounterContext)

	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION
	const isCommissionPendingActive = () => active === AsideMenu.COMMISSION_PENDING
	const isExceptionCompletedActive = () => active === AsideMenu.EXCEPTION_PENDING
	const isExceptionPendingActive = () => active === AsideMenu.EXCEPTION_COMPLETED
	const isCommissionCompletedActive = () => active === AsideMenu.COMMISSION_COMPLETED
	const isCommissionSysPendingActive = () => active === AsideMenu.COMMISSION_SYS_PENDING
	const isCommissionSysCompletedActive = () => active === AsideMenu.COMMISSION_SYS_COMPLETED

	const roles = userData?.authorities || []

	const invoicePermissions = Authority.getGfaInvoicePermissions(roles)
	const commissionPermissions = Authority.getCommissionAuthorizationPermissions(roles)
	const exceptionsPermissions = Authority.getGseExceptionPermissions(roles)
	const commissionSystemPermissions = Authority.getCommissionAuthorizationSysPermissions(roles)
	const isInternal = useInternalUser()

	const menuCommissionsItems: IMenuItemPrint[] = [
		{
			to: PATH_PENDING_COMMISSIONS_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_COMMISSIONS),
			id: PrintValues.PENDING,
		},
	].map((it) => ({ ...it, hidden: !commissionPermissions.canRead }))

	const menuCommissionCompleted: IMenuItem[] = [

		{
			to: PATH_COMPLETED_COMMISSIONS_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_COMMISSIONS),
		},
	].map((it) => ({ ...it, hidden: !commissionPermissions.canRead }))
	
	const menuExceptionsItems: IMenuItemPrint[] = [
		{
			to: PATH_PENDING_EXCEPTIONS_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_EXCEPTIONS),
			id: PrintValues.PENDING,
		},
	].map((it) => ({ ...it, hidden: !exceptionsPermissions.canRead }))

	const menuExceptionsCompleted: IMenuItem[] = [

		{
			to: PATH_COMPLETED_EXCEPTIONS_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_EXCEPTIONS),
		},
	].map((it) => ({ ...it, hidden: !exceptionsPermissions.canRead }))


	const menuPendingComissionSystemItems: IMenuItemPrint[] = [
		{
			to: PATH_PENDING_COMMISSIONS_AUTHORIZATION_SYS_PAGE,
			title: routerNameMap.get(PATH_COMMISSIONS),
			id: PrintValues.PENDING,
		},
	].map((it) => ({ ...it, hidden: !commissionSystemPermissions.canRead }))

	const menuCompletedComissionSystemItems: IMenuItem[] = [
		{
			to: PATH_COMPLETED_COMMISSIONS_AUTHORIZATION_SYS_PAGE,
			title: routerNameMap.get(PATH_COMMISSIONS),

		},
	].map((it) => ({ ...it, hidden: !commissionSystemPermissions.canRead }))



	const templateString = useCallback((title: string, id: PrintValues, pending: number) => {

		const templateRoutes: TemplateRoutes = {
			[PrintValues.PENDING]: `${translate(title)} (${pending})`,
		}

		return templateRoutes[id]

	}, [commission])

	const templateStringSys = useCallback((title: string, id: PrintValues) => {

		const templateRoutes: TemplateRoutes = {
			[PrintValues.PENDING]: `${translate(title)} (${commissionSys})`,
		}

		return templateRoutes[id]

	}, [pendingSys, commissionSys])

	const getPendingTotal = () => {
		const commissionsNumber = commissionPermissions.canRead? pendingCommissions : 0;
		const exceptionsNumber = exceptionsPermissions.canRead? pendingExceptions : 0;
		return commissionsNumber + exceptionsNumber
	}



	return (
		<>

			{(commissionPermissions.canRead || exceptionsPermissions.canRead) && (
				<AsideMenuItemWithSub
					title={`${translate('TITLE.PENDING')} (${getPendingTotal()})`}
					active={isCommissionPendingActive() || isExceptionPendingActive()}
					icon={<FontAwesomeIcon icon={faTriangleExclamation} className="fs-2x" />}
				>
					{commissionPermissions.canRead && menuCommissionsItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? templateString(item.title, item.id, pendingCommissions) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_PENDING)}
							/>
						))}
						{exceptionsPermissions.canRead && menuExceptionsItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? templateString(item.title, item.id, pendingExceptions) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_PENDING)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{(commissionPermissions.canRead || exceptionsPermissions.canRead) && (
				<AsideMenuItemWithSub
					title={translate('TITLE.COMPLETED2')}
					active={isCommissionCompletedActive() || isExceptionCompletedActive()}
					icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
				>
					{commissionPermissions.canRead && menuCommissionCompleted
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_COMPLETED)}
							/>
						))}
						{exceptionsPermissions.canRead && menuExceptionsCompleted
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_COMPLETED)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{commissionSystemPermissions.canRead && (
				<AsideMenuItemWithSub
					title={pendingSys ? `${translate('TITLE.PENDING')} (${pendingSys})` : `${translate('TITLE.PENDING')} (0)`}
					active={isCommissionSysPendingActive()}
					icon={<FontAwesomeIcon icon={faTriangleExclamation} className="fs-2x" />}
				>
					{menuPendingComissionSystemItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? templateStringSys(item.title, item.id) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_SYS_PENDING)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{commissionSystemPermissions.canRead && (
				<AsideMenuItemWithSub
					title={translate('TITLE.COMPLETED2')}
					active={isCommissionSysCompletedActive()}
					icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
				>
					{menuCompletedComissionSystemItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_SYS_COMPLETED)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

		</>
	)
}

export { AsideMenuMain }
