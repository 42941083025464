import { AxiosResponse } from 'axios';
import React, { SetStateAction, useRef, useState, useEffect } from 'react'
import { UseMutateFunction } from 'react-query';
import { InputActionMeta, MultiValue, SelectInstance } from 'react-select';
import { UseGetClients, UseGetKams } from 'request'
import { ClientList, KamList } from 'types/commisions/CommisionElementTypes';
import { useDebounce } from 'use-debounce';
import { MultiSelectChangeEvent, MultiSelectAllEvent } from 'primereact/multiselect';




export interface InputProps {
  username: string
  name: string
}

interface Props {
  setFilterData?: SetStateAction<any>
  filterData?: any
  fnData?: any
}


export enum SelectName {
  KAM = 'kam',
  CLIENT = 'client'
}

export const useFiltersAuthorization = ({ filterData, setFilterData, fnData }: Props) => {

  const [input, setInput] = useState<InputProps>({
    username: '',
    name: ''
  })

  const clearInputs = () => {
    setInput({
      username: '',
      name: ''
    })
  }




  const [debouncedKamsInput] = useDebounce(input.username, 500);
  const [debouncedClientInput] = useDebounce(input.name, 500);

  const { data: dataAuthorizator, isLoading: isLoadingDataAuthorizator } = UseGetKams({ username: debouncedKamsInput })
  const { data: dataClients, isLoading: isLoadingDataClients } = UseGetClients({ name: debouncedClientInput })

  const kamsSelect = dataAuthorizator &&
    [
      ...dataAuthorizator.data.map((item: KamList) => (
        { label: item.username, value: item.id }
      ))
    ]

  const clientsSelect = dataClients &&
    [
      ...dataClients.data.map((item: ClientList) => (
        { label: item.name, value: item.id }
      ))
    ]


  const [selectAllKam, setSelectAllKam] = useState(false)
  const [selectAllClient, setSelectAllClient] = useState(false)
  const [multiSelectKam, setMultiSelectKam] = useState<string[] | undefined>([])
  const [multiSelectClient, setMultiSelectClient] = useState<string[] | undefined>([])

  const username = multiSelectKam
  const serviceClient = multiSelectClient


  useEffect(() => {

    if (clientsSelect && multiSelectClient && clientsSelect.length == multiSelectClient.length) {

      fnData({
        username,
        serviceClient
      })
    }




  }, [multiSelectClient])

  useEffect(() => {

    if (kamsSelect && multiSelectKam && kamsSelect.length == multiSelectKam.length) {

      fnData({
        username,
        serviceClient
      })
    }
  }, [multiSelectKam])






  const cleanSelectKamAndClient = () => {
    setMultiSelectKam([])
    fnData({})
    setMultiSelectClient([])
  }



  const handleMultiSelect = (event: MultiSelectChangeEvent, id: SelectName) => {

    if (id === SelectName.KAM) {
      setMultiSelectKam(event.value)
      setSelectAllKam(event.value.length === kamsSelect?.length)
      fnData({
        serviceClient,
        username: event.value
      })
    }
    if (id === SelectName.CLIENT) {
      setMultiSelectClient(event.value)
      setSelectAllClient(event.value.length === clientsSelect?.length)
      fnData({
        username,
        serviceClient: event.value,
      })

    }

  }


  const onSelectAll = (e: MultiSelectAllEvent, id: SelectName) => {

    if (id === SelectName.KAM) {
      setMultiSelectKam(e.checked ? [] : kamsSelect?.map((item) => item.value))
      setSelectAllKam(!e.checked)
    }

    if (id === SelectName.CLIENT) {
      setMultiSelectClient(e.checked ? [] : clientsSelect?.map((item) => item.value))
      setSelectAllClient(!e.checked)

    }

  }





  return {
    kamsSelect,
    clientsSelect,
    input,
    isLoadingDataAuthorizator,
    isLoadingDataClients,
    clearInputs,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllKam,
    selectAllClient,
    setMultiSelectKam,
    setMultiSelectClient,
    setSelectAllKam,
    setSelectAllClient,
    onSelectAll,
  }

}
