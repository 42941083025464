import { gfaI18nMessages } from 'modules/i18n/domain/messages'
import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'

const GfaI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={gfaI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { GfaI18nProvider }
