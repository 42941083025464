import { UfinetActionButton } from "ufinet-web-components"
import { useTranslation } from "utils/translation/Translation"


interface Props {
    approveCommisionFn: () => void
    hideApprovedModal: () => void
    isLoadingApproveCommission: boolean
}


export const ApprovedBodyForm = ({ approveCommisionFn, hideApprovedModal, isLoadingApproveCommission }: Props) => {
    const translate = useTranslation()



    return (
        <div>

            <div className="d-flex justify-content-end">
                <UfinetActionButton
                    onClick={hideApprovedModal}
                    className='me-1 btn-secondary'

                    content={translate('BUTTON.CANCEL')}
                />
                <UfinetActionButton
                    onClick={approveCommisionFn}
                    isDisabled={isLoadingApproveCommission}
                    content={translate('BUTTON.CONFIRM')}
                />

            </div>

        </div>
    )
}
