export enum ContractedServiceType {
	OUTSOURCING = 'OUTSOURCING',
	OTROS = 'OTROS',
	PUNTUAL = 'PUNTUAL',
	VSAT = 'VSAT',
	EQUIPO = 'EQUIPO',
	ENERGIA = 'ENERGIA',
	CROSS_CONEXION = 'CROSS_CONEXION',
	FTTH = 'FTTH',
	TOWERING = 'TOWERING',
	CLOUD = 'CLOUD',
	CAPACIDAD = 'CAPACIDAD',
	INTERNET = 'INTERNET',
	FIBRA_OSCURA = 'FIBRA_OSCURA',
	COLOCATION = 'COLOCATION',
	INFRAESTRUCTURA = 'INFRAESTRUCTURA',
	LAN_SERVICES = 'LAN_SERVICES',
	SEGURIDAD = 'SEGURIDAD',
	VENTA_DE_EQUIPOS = 'VENTA_DE_EQUIPOS',
	HAAS = 'HAAS',
}

export enum ContractedServiceTypeId {
	OUTSOURCING = 226530005,
	OTROS = 912790000,
	PUNTUAL = 100000008,
	VSAT = 100000009,
	EQUIPO = 100000010,
	ENERGIA = 100000011,
	CROSS_CONEXION = 100000012,
	FTTH = 100000014,
	TOWERING = 100000015,
	CLOUD = 100000016,
	CAPACIDAD = 100000007,
	INTERNET = 100000001,
	FIBRA_OSCURA = 100000002,
	COLOCATION = 100000003,
	INFRAESTRUCTURA = 100000004,
	LAN_SERVICES = 226530006,
	SEGURIDAD = 100000018,
	VENTA_DE_EQUIPOS = 100000019,
	HAAS = 100000020,
}
