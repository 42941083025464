export const convertNumber = (number: number) => {

  if (number === null) return 0

  let numberWithDecimals = number.toFixed(2).toString();


  // numberWithDecimals = numberWithDecimals.replace(".", ",");


  return numberWithDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
