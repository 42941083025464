import { OrsServiceExceptionUpdateStateRequest } from 'modules/contractedService/application/ors/exception/update/dto/OrsServiceExceptionUpdateStateRequest'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { useMutation } from 'react-query'

export const useOrsServiceExceptionUpdateStateQuery = (
	orsServiceExceptionRepository: OrsServiceExceptionRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: () => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useMutation({
		mutationKey: 'ors-service-exception-update-states-query',
		mutationFn: (orsServiceExceptionUpdateRequest: OrsServiceExceptionUpdateStateRequest) =>
			orsServiceExceptionRepository.updateStates(orsServiceExceptionUpdateRequest, { notifyError: false }),
		onSuccess,
		onError,
	})
}
