import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


interface Props {
    content: string
}

export const OverlayRender = ({ content }: Props) => {

    const renderTooltip = (props: any) => (
        <Tooltip
            id="button-tooltip" {...props}>

            <p className='fw-bolder m-0'>  {content}</p>
        </Tooltip>
    );
    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={renderTooltip}
            >
                <p className='text-truncate'>{content}</p>
            </OverlayTrigger>

        </>
    )
}
