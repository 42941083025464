import React from 'react'
import { UfinetActionButton } from 'ufinet-web-components'
import { useTranslation } from 'utils/translation/Translation'



interface Props {
    hideApprovalModal: () => void
}

export const WarningModal = ({ hideApprovalModal }: Props) => {


    const translate = useTranslation()

    return (
        <div className='d-flex flex-column justify-content-end'>

            <p className='fs-4'>{translate('CONTAIN.MESSAGE.APPROVED')}</p>

            <div className='d-flex  justify-content-end'>
                <UfinetActionButton
                    onClick={hideApprovalModal}
                    className='me-1 btn-secondary'

                    content={translate('BUTTON.CANCEL')}
                />

            </div>
        </div>
    )
}
