import { orsServiceExceptionFindResponseToDomainEntity } from 'modules/contractedService/application/ors/exception/find/dto/OrsServiceExceptionFindResponse'
import { ExceptionStatus } from 'modules/contractedService/domain/ors/exception/ExceptionStatus'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { useQuery } from 'react-query'

export const useOrsServiceExceptionFindAllByStatusQuery = (
	orsServiceExceptionRepository: OrsServiceExceptionRepository, status: ExceptionStatus,
	{
		enabled = true,
		onSuccess,
		onError,
	}: {
		enabled?: boolean
		onSuccess?: (orsServiceExceptions: OrsServiceException[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery(`ors-service-exception-find-all-${status}-query`, () => orsServiceExceptionRepository.findAllOfUserByStatus({status: status}), {
		enabled,
		select: (data) => data.map(orsServiceExceptionFindResponseToDomainEntity),
		onSuccess,
		onError,
	})
}

export const UseQueryGetTotalExceptions = (
	orsServiceExceptionRepository: OrsServiceExceptionRepository, 
	enabled: boolean
  ) => {
	return useQuery(
	  "UseQueryGetTotalExceptions", 
	  async () => {
		try {
		  const response = await orsServiceExceptionRepository.findTotal();
		  return response;
		} catch (error) {
		  throw error;
		}
	  }, 
	  {
		enabled
	  }
	);
  };


