import { createContext } from "react"



export interface CounterContextProps {
    refetchDataCommissions: () => void
    refetchTotalExceptions: () => void
    refetchDataSys: () => void
    pendingCommissions: number
    pendingExceptions: number
    commission: number
    pendingSys: number
    commissionSys: number
}


export const CounterContext = createContext<CounterContextProps>({} as CounterContextProps)


