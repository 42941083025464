import React from 'react'
import { UfinetActionButton } from 'ufinet-web-components'
import { useTranslation } from 'utils/translation/Translation'




interface Props {
    hideRevertModal: () => void
    revertCommisionFn: () => void
    isLoadingRevertCommission: boolean
}

export const RevertBodyModal = ({ hideRevertModal, revertCommisionFn, isLoadingRevertCommission }: Props) => {


    const translate = useTranslation()
    return (
        <div>

            <div className="d-flex justify-content-end">
                <UfinetActionButton
                    onClick={hideRevertModal}
                    className='me-1 btn-secondary'

                    content={translate('BUTTON.CANCEL')}
                />
                <UfinetActionButton
                    onClick={revertCommisionFn}
                    isDisabled={isLoadingRevertCommission}
                    content={translate('BUTTON.CONFIRM')}
                />

            </div>

        </div>
    )
}
