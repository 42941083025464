import { UseQueryGetTotalExceptions } from "modules/contractedService/application/ors/exception/find/useOrsServiceExceptionFindQuery"
import { HttpOrsServiceExceptionRepository } from "modules/contractedService/infrastructure/ors/exception/HttpOrsServiceExceptionRepository"
import { useContext, useMemo } from "react"
import { UseQueryGetTotalCommissions, UseQueryGetTotalCommissionsSys } from "request"
import { AuthContext, Authority, AuthStatus } from "ufinet-web-functions"
import { CounterContext } from './CounterContext'


interface Props {
    children: JSX.Element | JSX.Element[]
}

export const CounterProvider = ({ children }: Props) => {
    const authData = useContext(AuthContext)
    const {canRead: canReadExceptions} = Authority.getGseExceptionPermissions(authData.userData?.authorities || [])
	const orsServiceExceptionRepository = useMemo(() => HttpOrsServiceExceptionRepository(authData), [authData])
    const { data: dataTotalCommissions, refetch: refetchDataCommissions } = UseQueryGetTotalCommissions()
    const { data: dataTotalExceptions, refetch: refetchTotalExceptions } = UseQueryGetTotalExceptions(orsServiceExceptionRepository, (authData.status === AuthStatus.LOGGED_IN) && canReadExceptions)
    const { data: dataTotalSys, refetch: refetchDataSys } = UseQueryGetTotalCommissionsSys()

    return (
        <CounterContext.Provider
            value={{
                pendingCommissions: dataTotalCommissions?.pending || 0,
                pendingExceptions: dataTotalExceptions?.pending || 0,
                commission: dataTotalCommissions?.commissions || 0,
                refetchDataCommissions,
                refetchTotalExceptions,
                refetchDataSys,
                pendingSys: dataTotalSys?.pending || 0,
                commissionSys: dataTotalSys?.commissions || 0,
            }}
        >
            {children}

        </CounterContext.Provider>
    )
}