import { SelectName } from "hooks/useFiltersAuthorization"
import { useTranslation } from "utils/translation/Translation"
import { MultiSelect, MultiSelectChangeEvent, MultiSelectAllEvent } from 'primereact/multiselect';

interface Props {
  kamsSelect?: { label: string, value: string }[],
  clientsSelect?: { label: string, value: string }[],
  onChangeHandle: (event: MultiSelectChangeEvent, id: SelectName) => void,
  isLoadingDataAuthorizator?: boolean,
  isLoadingDataClients?: boolean,
  [key: string]: any
  kamFilter?: boolean
  clientFilter?: boolean
  multiSelectKam: string[] | undefined
  multiSelectClient: string[] | undefined
  onSelectAll: any
  selectAllClient: any
  selectAllKam: any

}



export const MultiSelectFiltersHeaders = ({
  kamsSelect,
  onChangeHandle,
  isLoadingDataAuthorizator,
  clientsSelect,
  isLoadingDataClients,
  kamFilter = true,
  clientFilter = true,
  multiSelectKam,
  multiSelectClient,
  selectAllClient,
  selectAllKam,
  onSelectAll,
  ...props }: Props) => {

  const translate = useTranslation()


  const styles = {
    maxWidth: 145,
    width: 135,
  }



  return (
    <>
      <div className="d-flex">


        {
          (kamFilter) && (
            <div className="d-flex flex-column me-2">
              <label>{translate('COL.COMMISSION.KAM')}</label>


              <MultiSelect
                loading={isLoadingDataAuthorizator}
                selectAll={selectAllKam}
                onSelectAll={(e: MultiSelectAllEvent) => onSelectAll(e, SelectName.KAM)}
                selectedItemsLabel={`${multiSelectKam && multiSelectKam.length} seleccionados`}
                showClear
                placeholder={translate('COL.COMMISSION.KAM')}
                maxSelectedLabels={3}
                // className="col col-xl-4 col-md-6 col-sm-12"
                // className="col"
                value={multiSelectKam}
                style={styles}
                onChange={(e) => onChangeHandle(e, SelectName.KAM)}
                options={kamsSelect}
                filter
              />


            </div>
          )
        }

        {
          (clientFilter) && (
            <div className="d-flex flex-column">
              <label>{translate('COL.COMMISSION.SERVICECLIENT')}</label>

              <MultiSelect
                loading={isLoadingDataClients}
                onSelectAll={(e: MultiSelectAllEvent) => onSelectAll(e, SelectName.CLIENT)}
                selectAll={selectAllClient}
                selectedItemsLabel={`${multiSelectClient && multiSelectClient.length} seleccionados`}
                showClear
                placeholder={translate('COL.COMMISSION.SERVICECLIENT')}
                maxSelectedLabels={3}
                virtualScrollerOptions={{ itemSize: 50 }}
                // className="col col-xl-4 col-md-6 col-sm-12"
                // className="col"
                value={multiSelectClient}
                style={styles}
                onChange={(e) => onChangeHandle(e, SelectName.CLIENT)}
                options={clientsSelect}
                filter
              />


            </div>
          )
        }



        {/* <div className="col">
       
      </div> */}
      </div>
    </>
  )
}
