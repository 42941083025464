import { ExceptionStatus } from 'modules/contractedService/domain/ors/exception/ExceptionStatus'
import { HttpOrsServiceExceptionRepository } from 'modules/contractedService/infrastructure/ors/exception/HttpOrsServiceExceptionRepository'
import { ExceptionManagementTable } from 'pages/authorization/pending/exception/table/ExceptionManagementTable'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

type ExceptionManagementPageProps = {
	variant: ExceptionStatus
}
const ExceptionManagementPage: FC<ExceptionManagementPageProps> = ({variant} : ExceptionManagementPageProps) => {
	const authData = useContext(AuthContext)
	const orsServiceExceptionRepository = useMemo(() => HttpOrsServiceExceptionRepository(authData), [authData])

	return (
		<UfinetBox>
			<ExceptionManagementTable orsServiceExceptionRepository={orsServiceExceptionRepository} variant={variant} />
		</UfinetBox>
	)
}
export { ExceptionManagementPage }
