import React, { useState, useEffect, useContext, useMemo, useCallback, } from 'react'
import { useIntl } from 'react-intl'
import { MultipleDeleter, Table, UfinetAsyncButton, UfinetBox, UfinetButton, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, useTranslator, } from 'ufinet-web-functions'
import { colsPendingAuthorizationSys } from './PendingAuthorizationSysColData'
import { UseDownloadFileAuthorizatorPendingSys, UseMutationDataAuthPending, UseMutationDataAuthPendingSys, } from 'request'

import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/commisions/CommisionElementTypes'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'


import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'
import { useFiltersAuthorizationSys } from 'hooks/useFiltersAuthorizationSys'
import { ErrorResponse } from 'types/typesAndValues'
import { toast } from 'react-toastify'


const PendingAuthorizationSysPage = () => {
    const translate = useTranslator()
    const intl = useIntl()



    const colsPendingPage = useMemo(() => colsPendingAuthorizationSys(intl), [intl])

    const authData = useContext(AuthContext)

    const userEmail = authData.userData?.username
    const roles = authData.userData?.authorities || []
    const permissions = Authority.getCommissionAuthorizationSysPermissions(roles)


    const [selectedValues, setSelectedValues] = useState<CommissionElement[]>([])
    const [paging, setPaging] = useState<any>()
    const [filterData, setFilterData] = useState<any>({})



    const cbSuccessDataAuthPending = ({ data }: any) => {
    }

    const cbSuccessDownloadFile = ({ data }: any) => {
    }


    const { mutate: fnDataPendingSys, data: dataPendingSys, isLoading: isLoadingDataPendingSys } = UseMutationDataAuthPendingSys(cbSuccessDataAuthPending)
    const { mutate: downloadFile, isLoading: isLoadingDownloadingFile, isError, error: errorMessage } = UseDownloadFileAuthorizatorPendingSys(cbSuccessDownloadFile)


    const showToasDownloadtErrMessage = () => {
        if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
            const errorResponse = errorMessage as ErrorResponse;
            toast.error(`${translate("ERROR.DOWNLOAD.MESSAGE")}`, { toastId: 'Error6' });
        }
    }




    const {
        kamsSelect,
        isLoadingDataAuthorizator,
        clientsSelect,
        isLoadingDataClients,
        handleMultiSelect,
        multiSelectKam,
        multiSelectClient,
        cleanSelectKamAndClient,
        username,
        serviceClient,
        selectAllClient,
        selectAllKam,
        onSelectAll,
    } = useFiltersAuthorizationSys({ filterData, setFilterData, fnData: fnDataPendingSys })





    const isSelectValues = selectedValues && selectedValues.length > 0






    useEffect(() => {
        fnDataPendingSys({})
    }, [])


    useEffect(() => {
        if (dataPendingSys) {
            const parsePagination = JSON.parse(dataPendingSys?.headers['x-pagination'])
            const formattedPagination = {
                totalElements: parsePagination.totalCount,
                totalPages: parsePagination.totalPages,
                pageSize: parsePagination.pageSize,
                pageNumber: parsePagination.currentPage,
            }
            setPaging(formattedPagination)
        }
    }, [dataPendingSys])

    const onFilterClear = (): void => {
        cleanSelectKamAndClient()
        setFilterData({})
        setSelectedValues([])
    }

    const onFilter = (e: DataTablePageEvent): void => {
        const formattedFilters = formatTableFilters(e as any)
        setFilterData({
            ...filterData,
            ...formattedFilters,
            username,
            serviceClient,
        })

        fnDataPendingSys({
            ...filterData,
            ...formattedFilters,
            username,
            serviceClient,
        })

    }

    const onPage = (e: DataTablePageEvent): void => {

        setFilterData({
            ...filterData,
            pageNumber: e.page,
            pageSize: e.rows,
            username,
            serviceClient,
        })
        fnDataPendingSys({
            ...filterData,
            pageNumber: e.page,
            pageSize: e.rows,
            username,
            serviceClient,
        })
    }

    const onSort = (e: DataTablePageEvent): void => {
        const finalOrder = tableSortValueToSortDirection(e.sortOrder)

        setFilterData({
            ...filterData,
            sortField: e.sortField,
            sortOrder: finalOrder,
            username,
            serviceClient,
        })
        fnDataPendingSys({
            ...filterData,
            sortField: e.sortField,
            sortOrder: finalOrder,
            username,
            serviceClient,
        })
    }

    const onSelectionChange = useCallback((values: any[]) => {
        setSelectedValues(values)
    }, [])







    const getHeadersButtons = () => (
        <>
            {permissions.canWrite && (
                <UfinetButton
                    isDisabled={isLoadingDownloadingFile}
                    className="me-3"
                    icon={faDownload}
                    onClick={() => downloadFile(filterData)}
                    content={translate('BUTTON.DOWNLOAD')}
                />
            )}

        </>
    )


    const filterButtons = () => {
        return (
            <>

                <MultiSelectFiltersHeaders
                    // kamFilter={false}
                    onSelectAll={onSelectAll}
                    selectAllClient={selectAllClient}
                    selectAllKam={selectAllKam}
                    multiSelectClient={multiSelectClient}
                    multiSelectKam={multiSelectKam}
                    clientsSelect={clientsSelect}
                    kamsSelect={kamsSelect}
                    onChangeHandle={handleMultiSelect}
                    isLoadingDataAuthorizator={isLoadingDataAuthorizator}
                    isLoadingDataClients={isLoadingDataClients}
                />
            </>
        )

    }






    return (
        <>

            <UfinetBox>
                {isError && showToasDownloadtErrMessage()}

                <UfinetSectionBox>
                    <Table
                        filterButtons={filterButtons()}
                        dataKey="id"
                        selectedValues={selectedValues}
                        // onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
                        headerButtons={getHeadersButtons()}
                        enableSearchFilter={false}
                        // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
                        cols={colsPendingPage}
                        content={dataPendingSys ? dataPendingSys?.data?.commissions?.map((item: CommissionElement) => ({
                            ...item,
                            username: item.user.username,
                            idService: item.service.idService,
                            saleMonth: item.service.saleMonth,
                            commissionType: item.commission.commissionType,
                            exchangeRate: item.commission.exchangeRate,
                            saleYear: item.service.saleYear,
                            authorizationState: item.authorization.authorizationState,
                            id: item.commission.id,
                            achievementFactor: item.commission.achievementFactor,
                            baseAmount: item.commission.baseAmount,
                            percentageCommission: item.commission.percentageCommission,
                            backlog: item.service.backlog,
                            totalAmount: item.commission.totalAmount,
                            commissionTypePercentage: item.commission.commissionTypePercentage,
                            idServiceCRM: item.service.idServiceCRM,
                            serviceName: item.service.serviceName,
                            serviceClient: item.service.serviceClient,
                            baseAmountUSD: item.commission.baseAmountUSD,
                            totalAmountUSD: item.commission.totalAmountUSD,
                            additionalInfo: item.commission.additionalInfo,
                        })) : []}
                        actionBodyTemplate={<></>}
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
                        emptyMessage={isLoadingDataPendingSys ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
                        onFilterClear={onFilterClear}
                        lazySettings={
                            paging && {
                                ...paging,
                                loading: isLoadingDataPendingSys,
                                onFilter,
                                onPage,
                                onSort,
                            }
                        }
                    // customClassFilters="col col-gap-2"
                    // customClassFilters="col-xl-6  col-md-12 col-sm-12"
                    // customClassHeaders="col-xl-6 col-md-12 col-sm-12 ps-0 mt-9 pe-0"
                    />
                </UfinetSectionBox>

            </UfinetBox>
        </>
    )
}

export { PendingAuthorizationSysPage }

