import { i18nMessages } from 'ufinet-web-components'
import gfaEnMessages from './en.json'
import gfaEsMessages from './es.json'
import gfaPtMessages from './pt.json'

export const gfaI18nMessages: i18nMessages = {
	en: {
		...gfaEnMessages,
	},
	es: {
		...gfaEsMessages,
	},
	pt: {
		...gfaPtMessages,
	},
}
