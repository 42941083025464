import { ContractedServiceTypeId } from 'modules/contractedService/domain/ContractedServiceType'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionState } from 'modules/contractedService/domain/ors/exception/state/OrsServiceExceptionState'
import { useCallback } from 'react'
import { ColData, UfinetCheckbox } from 'ufinet-web-components'
import {
	formatCellNumber,
	getEnumKeyFromEnumValue,
	noContentPlaceholder,
	tableCostsFormattingSettings,
	tableDeadlineFormattingSettings,
	useTranslator,
} from 'ufinet-web-functions'

type HookInput = {
	canEditState: boolean
	orsServiceExceptions: OrsServiceException[]
	orsAllServiceExceptionsHeaderCheck: OrsServiceExceptionState
	onExceptionStateChange: (input: { serviceIds: string[]; selectedState: OrsServiceExceptionState }) => void
	onExceptionAllStateChange: (selectedState: OrsServiceExceptionState) => void
}

type HookOutput = {
	columns: ColData[]
}
const useExceptionManagementColsData: (input: HookInput) => HookOutput = ({
	canEditState,
	orsServiceExceptions,
	orsAllServiceExceptionsHeaderCheck,
	onExceptionStateChange,
	onExceptionAllStateChange,
}) => {
	const translate = useTranslator()

	const filterServiceTypes = [...new Set(orsServiceExceptions.map((it) => it.service.type))].sort()
	const filterServiceStates = [...new Set(orsServiceExceptions.map((it) => it.service.state.value))].sort()

	const headerExceptions = useCallback(
		(titleHeader: string, exceptionState: OrsServiceExceptionState) => (
			<div className="d-flex align-items-center">
				<div className="me-1">{translate(titleHeader)}</div>
				<div>
					<UfinetCheckbox
						name={
							exceptionState === OrsServiceExceptionState.WITH_EXCEPTION
								? 'with-exception-all-check'
								: 'without-exception-all-check'
						}
						checked={orsAllServiceExceptionsHeaderCheck === exceptionState}
						className="mb-0"
						onChange={() =>
							onExceptionAllStateChange(
								orsAllServiceExceptionsHeaderCheck === exceptionState ? OrsServiceExceptionState.EMPTY : exceptionState
							)
						}
					/>
				</div>
			</div>
		),
		[onExceptionAllStateChange, orsAllServiceExceptionsHeaderCheck, translate]
	)

	const columns: ColData<OrsServiceException>[] = [
		{
			field: 'exception.state',
			hidden: !canEditState,
			header: headerExceptions(
				'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.WITHOUT_EXCEPTION',
				OrsServiceExceptionState.WITHOUT_EXCEPTION
			),
			width: '9%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: any) => (
				<div className="w-100 d-flex align-items-center justify-content-center">
					<UfinetCheckbox
						name={`without-exception-check-${row.service.id}`}
						className="mb-0"
						checked={row.exception.state === OrsServiceExceptionState.WITHOUT_EXCEPTION}
						onChange={() =>
							onExceptionStateChange({
								serviceIds: [row.service.id],
								selectedState:
									row.exception.state === OrsServiceExceptionState.WITHOUT_EXCEPTION
										? OrsServiceExceptionState.EMPTY
										: OrsServiceExceptionState.WITHOUT_EXCEPTION,
							})
						}
					/>
				</div>
			),
		},
		{
			field: 'exception.state',
			hidden: !canEditState,
			header: headerExceptions(
				'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.WITH_EXCEPTION',
				OrsServiceExceptionState.WITH_EXCEPTION
			),
			width: '9%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: any) => (
				<div className="w-100 d-flex align-items-center justify-content-center">
					<UfinetCheckbox
						name={`with-exception-check-${row.service.id}`}
						className="mb-0"
						checked={row.exception.state === OrsServiceExceptionState.WITH_EXCEPTION}
						onChange={() =>
							onExceptionStateChange({
								serviceIds: [row.service.id],
								selectedState:
									row.exception.state === OrsServiceExceptionState.WITH_EXCEPTION
										? OrsServiceExceptionState.EMPTY
										: OrsServiceExceptionState.WITH_EXCEPTION,
							})
						}
					/>
				</div>
			),
		},
		{
			field: 'service.administrativeCode',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.ADMINISTRATIVE_CODE',
			width: '9%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: false,
		},
		{
			field: 'service.name',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.NAME',
			width: '11%',
			nonFilterable: true,
			nonSortable: true,
			nonSearchable: false,
		},
		{
			field: 'client.name',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.CLIENT_NAME',
			width: '10%',
			nonFilterable: false,
			nonSortable: false,
			nonSearchable: false,
			body: (row: any) => row.client?.name || noContentPlaceholder,
		},
		{
			field: 'service.type',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.TYPE',
			width: '6%',
			nonFilterable: false,
			nonSortable: false,
			nonSearchable: true,
			filterOptions: filterServiceTypes.map((serviceTypeId) => ({
				value: serviceTypeId,
				label: translate(`CONTRACT.SERVICE.TYPE.${getEnumKeyFromEnumValue(ContractedServiceTypeId, serviceTypeId)}`),
			})),
			body: (row: any) => translate(`CONTRACT.SERVICE.TYPE.${ContractedServiceTypeId[row.service.type]}`),
		},
		{
			field: 'term',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.TERM',
			width: '6%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: any) => formatCellNumber(row.term, { ...tableDeadlineFormattingSettings, placeholderOnZero: true }),
		},
		{
			field: 'arcDifferential',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.ARC_DIFFERENTIAL',
			width: '8%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: any) => formatCellNumber(row.arcDifferential, tableCostsFormattingSettings),
		},
		{
			field: 'kam.name',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.KAM_NAME',
			width: '10%',
			nonFilterable: false,
			nonSortable: false,
			nonSearchable: false,
			body: (row: any) => row.kam?.name || noContentPlaceholder,
		},
		{
			field: 'service.state.value',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE',
			width: '8%',
			nonFilterable: false,
			nonSortable: false,
			nonSearchable: true,
			filterOptions: filterServiceStates.map((value) => ({
				value,
				label: translate(`CONTRACT.SERVICE.STATE.${value}`),
			})),
			body: (row: any) => translate(`CONTRACT.SERVICE.STATE.${row.service.state.value}`),
		},
		{
			field: 'daysCurrentStatus',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.DAYS_CURRENT_STATUS',
			width: '6%',
			nonFilterable: true,
			nonSortable: false,
			nonSearchable: true,
			body: (row: any) => (!row.daysCurrentStatus ? noContentPlaceholder : Math.round(row.daysCurrentStatus)),
		},
		{
			field: 'arcCountry.name',
			header: 'CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.ARC_COUNTRY',
			width: '8%',
			nonFilterable: false,
			nonSortable: false,
			nonSearchable: false,
			body: (row: any) => row.arcCountry?.name || noContentPlaceholder,
		},
	]
	return {
		columns,
	}
}

export { useExceptionManagementColsData }
